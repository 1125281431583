import React from "react";
import {Col, Row} from "reactstrap";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import Check from "./fields/check";
import Text from "./fields/text";
import Textarea from "./fields/textarea";
import DatePicker from "./fields/date";
import CartAddressForm from "./cartAddressForm";
import BriqpayPaymentPage from "../../views/BriqpayPaymentPage";
const required = (value) => (value ? undefined : 'Required');
const renderCheckField = ({
                              input,
                              placeholder,
                              label,
                              meta: { touched, error },
                          }) => (
    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={(touched && error)}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
);

const renderTextField = ({
                             input,
                             label,
                             placeholder,
                             meta: { touched, error },
                             type,
                         }) => (
    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
);

const renderTextareaField = ({
                                 input,
                                 placeholder,
                                 label,
                                 meta: { touched, error },
                             }) => (
    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
);
const renderDatePickerField = ({
                                   input,
                                   placeholder,
                                   label,
                                   meta: { touched, error },
                               }) => (
    <DatePicker
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
);

class BriqpayFactureForm extends React.Component {

    constructor(props) {
        super(props);
        this.briqpayFormRef = React.createRef();
    }



    render() {
        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form ref={this.briqpayFormRef} onSubmit={handleSubmit} className="login-form facture-form">
                <Row>
                    <Col lg="12" style={{ marginTop: 5, marginBottom: 8 }}>
                        <div className={!this.props.valid && !this.props.validForm ? 'check-wrap error' : 'check-wrap'}>
                            <Field
                                name="termsAndConditions"
                                component={renderCheckField}
                                onChange={(event, newValue, previousValue, name) => {
                                    this.props.onFormChange({name, value: newValue});
                                }}
                                label={
                                    <>
                                        {'Jag har läst och godkänner '.translate(this.props.lang)}
                                        <Link to="/page/kopvillkor">
                                            {'köpvillkor'.translate(this.props.lang)}
                                        </Link>
                                    </>
                                }
                                placeholder=""
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>



                    <h6 className="facture-form-text">
                        {'Frakt'.translate(this.props.lang)}
                    </h6>
                    <p className="facture-form-text">
                        {'Leverans sker vanligtvis inom 4-7 arbetsdagar från att ordern lagts, men vi reserverar oss för eventuella fördröjningar vid hög belastning. Önskat avsändningsdatum avser det datum då ni önskar att ordern skickas från vårt lager, där fraktbolaget sedan adderar leveranstid beroende på var i landet leveransen ska ske. Ni kan spåra er leverans genom att logga in på ert konto och klicka på "Mina orders". För frågor om er order, kontakta oss på order@venturedesign.se och ange ert ordernummer.'.translate(
                            this.props.lang,
                        )}
                    </p>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                onChange={(event, newValue, previousValue, name) => {
                                    this.props.onFormChange({name, value: newValue});
                                }}
                                name="deliveryDate"
                                component={renderDatePickerField}
                                label={'Önskat avsändningsdatum (om inget anges skickas ordern så snart som möjligt)'.translate(this.props.lang)}
                                placeholder={'Lämna tomt för snabbast möjliga leverans'.translate(
                                    this.props.lang,
                                )}
                            ></Field>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                onChange={(event, newValue, previousValue, name) => {
                                    this.props.onFormChange({name, value: newValue});
                                }}
                                name="freightMarks"
                                component={renderTextField}
                                label={'Övrig information till lagret:'.translate(
                                    this.props.lang,
                                )}
                            ></Field>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                onChange={(event, newValue, previousValue, name) => {
                                    this.props.onFormChange({name, value: newValue});
                                }}
                                name="orderReference"
                                component={renderTextField}
                                label={'Ange orderreferens:'.translate(this.props.lang)}
                                placeholder={'Valfritt, ange önskad orderreferens'.translate(
                                    this.props.lang,
                                )}
                            ></Field>
                        </div>
                    </Col>


                </Row>
            </form>
        );
    }
}
BriqpayFactureForm = reduxForm({
    form: 'briqpayFactureForm', // a unique identifier for this form
})(BriqpayFactureForm);
export default BriqpayFactureForm
